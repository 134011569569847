export const subtractDaysFromDate = (numDays, originalDate = new Date()) => {
    originalDate.setDate(originalDate.getDate() - numDays);
    return originalDate;
}

export const defaultEndDate = (monthNum = 9) => {
    let base_date = new Date();
    let base_year = base_date.getFullYear();

    if (base_date.getMonth() >= monthNum) base_year += 1;

    return new Date(base_year, monthNum, 1);
}

export const defaultStartDate = (monthNum = 9) => {
    let date = defaultEndDate(monthNum);
    date.setFullYear(date.getFullYear() - 1);
    return date;
}

export const appStartDate = () => {
    return new Date(2022, 0, 1, 0, 0, 0, 0);
}

export const addDaysToDate = (numDays, originalDate = new Date()) => {
    originalDate.setDate(originalDate.getDate() + numDays);
    return originalDate;
}